
import { getRedirectUrl, removeRedirectUrl } from '@/utils/cookies';
import { Component, Vue } from 'vue-property-decorator';

@Component({
})

export default class extends Vue {
  created() {
    if (this.$route.name === 'PaymentFail') {
      this.$message.error('결제에 실패했습니다.');
    }
    if (this.$route.name === 'PaymentReserveFull') {
      this.$message.error('선택하신 예약이 마감되었습니다.');
    }
    const redirectUrl = getRedirectUrl();
    removeRedirectUrl();
    if (redirectUrl) this.$router.push({ path: redirectUrl });
    else this.$router.push({ path: '/' });
  }
}
